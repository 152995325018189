

































































import { LayoutStoreModule } from '@/store/LayoutModule';
import { Component, Vue } from 'vue-property-decorator'
import { CusRouteConfig } from '@/router'
@Component({
  name: 'layout'
})
export default class MenuBox extends Vue {

  get currentActive() {
    return LayoutStoreModule.asideMenuActive;
  }
  get menuStatus() {
    return LayoutStoreModule.asideMenuStatus;
  }
  // 菜单列表
  get menuList() {
    return LayoutStoreModule.getAsideMenuList;
  }
  get menuApperance() {
    return LayoutStoreModule.asideMenuApperance;
  }
  // 当前菜单是否展开/选中
  get isMenuActive() {
    return (...index: number[]) => {
      if (this.currentActive.length === 0) {
        return false;
      }
      if (this.currentActive.length == index.length && this.currentActive.join('') === index.join('')) {
        return true;
      }
      return
    }
  }
  get isMenuShowAllItem() {
    return (index: number) => {
      return this.menuStatus.indexOf(index) >= 0 
    }
  }
  expandMenuItem(index: number) {
    LayoutStoreModule.shouldExpandMenuItem(index);
  }
  // 侧边栏菜单菜单点击
  menuTitleClick(...index: number[]) {
    if (index.length == 1) {
      const i = index[0]
      // 若果不是界面则展开菜单子项
      if (this.menuList[i].meta.isNotPage) {
        return this.expandMenuItem(i);
      }
    }
    const route = this.getRouteFormIndex(index, this.menuList);
    const { name, query, params } = this.$route;
    if (route) {
      // 判断点击的菜单去的界面是否是当前界面
      if(route.name === name) {
        const paramsKeys = Object.keys(params);
        if (paramsKeys.length ) {
          if(JSON.stringify(params) === JSON.stringify(route.meta.params)){
            return;
          }
        } else {
          return;
        }
      }
      const newRoute: any = {
        name: route.name
      }
      if (route.meta.query) newRoute.query = route.meta.query;
      if (route.meta.params) newRoute.params = route.meta.params;
      this.$router.push(newRoute);
    }
  }
  getRouteFormIndex(
    indexs: number[],
    routes: CusRouteConfig[],
    i: number = 0
  ): undefined | CusRouteConfig {
    if (routes.length == 0) return undefined;
    if (i + 1 == indexs.length) {
      return routes[indexs[i]];
    }
    if (i + 1 < indexs.length) {
      return this.getRouteFormIndex(indexs, routes[indexs[i]].children || [], ++i);
    }
  }
}
