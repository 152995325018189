















import { LayoutStoreModule } from '@/store/LayoutModule';
import {Component, Vue, Watch} from 'vue-property-decorator';

@Component
export default class TagsBox extends Vue{
  get navList(){
    return LayoutStoreModule.navList;
  }
  get navItemActive(){
    return LayoutStoreModule.navItemActive;
  }
  offset = 0;
  @Watch('navItemActive')
  onNavItemActiveChange(newVal: number){
    this.$nextTick(()=>{
      const TagsBoxList = document.querySelector('.tabs-header') as HTMLDivElement;
      const tabItemDom = document.querySelectorAll('.tab-item')[newVal] as HTMLDivElement;
      let dis = TagsBoxList.offsetWidth - tabItemDom.offsetLeft - tabItemDom.offsetWidth;
      if ( dis < 0 ) {
        this.offset = dis - 20;
      } else {
        this.offset = 0;
      }
    })
  }
  onTabItemClick(index: number){
    if(index === this.navItemActive) return;
    const {name, query, params} = this.navList[index];
    this.$router.push({name: name || 'adminIndex', query, params});
  }
  onRemoveTabItem(index: number){
    LayoutStoreModule.removeNavItem(index);
  }
}
