




































import { CusRoute, resetRouter } from '@/router';
import { LayoutStoreModule } from '@/store/LayoutModule';
import { Component, Vue } from 'vue-property-decorator';
import Vi18n, { setLanguage, getLanguage, LanguageType } from '@/lang'

import { UserStoreModule } from '@/store/UserModule';

@Component({
  name: 'header-box'
})
export default class HeaderBox extends Vue{
  language: LanguageType = LanguageType.zh_cn;
  tabsList: any[] = [];
  created(){
    this.language = getLanguage() as LanguageType || LanguageType.zh_cn
  }
  // 设置语言
  languageChange(e: LanguageType){
    setLanguage(e);
    Vi18n.locale = e;
  }
  // 获取面包屑导航
  get breadcrumbList(){
    return LayoutStoreModule.breadcrumbList;
  }
  // 侧边栏样式
  get asideMenuApperance(){
    return LayoutStoreModule.asideMenuApperance;
  }
  get userInfo(){
    return UserStoreModule.userInfo;
  }
  // 面包屑导航栏点击事件
  handleBreadCrumbClick(index: number){
    if (index == this.breadcrumbList.length - 1) return; 
    const route = this.breadcrumbList[index];
    if(route.meta.isNotPage || !route.name) return;
    const newRoute: any = {
      name: route.name,
    }
    if(route.meta.query) newRoute.query = route.meta.query;
    if(route.meta.params) newRoute.params = route.meta.params;
    this.$router.push(newRoute);
  }
  shouldExpandAsideMenu(){
    LayoutStoreModule.shouldExpandAsideMenu();
  }
  exit(){
    UserStoreModule.userLogout()
  }
}
