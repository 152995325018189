

























import {Component, Vue, Watch} from 'vue-property-decorator'
import MenuBox from './components/menu_box.vue'
import HeaderBox from './components/header_box.vue'
import TagsBox from './components/tags_box.vue'
import { LayoutStoreModule } from '../store/LayoutModule'
import { CusRoute, addRouteByPermission, resetRouter } from '@/router'
import { UserStoreModule } from '../store/UserModule'
import { getExpiresIn, getLoginTime  } from '@/utils/local_storage'
@Component({
  name: 'layout',
  components: {
    MenuBox, HeaderBox, TagsBox
  }
})
export default class Layout extends Vue {
  // 定时器 绑定isLogin
  private time: any = null;

  get asideMenuApperance(){
    return LayoutStoreModule.asideMenuApperance;
  }
  get key(){
    return this.$route.path
  }
  get token() {
    return UserStoreModule.accessToken
  }
  // 获取当前登录人id
  get userId() {
    return UserStoreModule.userInfo.id
  }
  @Watch('$route')
  onRouteChange(newVal: CusRoute){
    if (newVal.fullPath.indexOf('/admin/') < 0) return;
    LayoutStoreModule.setMenuAndBreadFromRoute(newVal);
  }
  private created() {
    this.isLogin();
  }
  // 一分钟请求一次查看是否已经登录
  private async isLogin() {
    if(this.token) {
      this.Interval();
      if(!this.userId) {
        await UserStoreModule.getUserData()
        this.tabBar();
      }
    }else {
      this.clearTime();
      this.$router.push('/login');
    }
  }
  // 标签栏
  private tabBar() {
    LayoutStoreModule.setMenuAside({
      isSupperPermission: UserStoreModule.isSupperPermission, 
      permission: UserStoreModule.myPermission, 
      schoolList: UserStoreModule.mySchool
    });
    resetRouter()
    addRouteByPermission(UserStoreModule.isSupperPermission, UserStoreModule.myPermission);
    LayoutStoreModule.setMenuAndBreadFromRoute(this.$route);
  }
  // 清除定时器
  private clearTime() {
    clearInterval(this.time);
    this.time = null;
  }
  // 定时器
  private Interval() {
    this.time = setInterval( async () => {
      const expitesIn: number = Number(getExpiresIn()); // 登录最长时间(目前是两小时)
      let loginTime: any = getLoginTime(); // 登录时间
      loginTime = Number(new Date(loginTime).getTime() / 1000).toFixed(0);
      const newTime: any = Number(new Date().getTime() / 1000).toFixed(0);
      const timeDifference: number = newTime - loginTime; // 当前时间和登录时间的时差
      // 如果时差小于登录最长限制时间，查看是否剩下10分钟内 是就重新获取cookie,否则不动
      if(Number(expitesIn - timeDifference) / 60 < 10) {
        try {
          if(this.token) {
            await UserStoreModule.checkLogin(); // 如果token报错 退出登录
          }
          this.clearTime();
          this.isLogin();
        }catch (err) {
          this.clearTime();
          await UserStoreModule.userLogout();
        }
      }
    }, 60000)
  }
}
